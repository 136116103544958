import { Alert, AlertColor, Snackbar } from '@mui/material';
import * as React from 'react';

type SnackbarData = {
  readonly message;
  readonly severity?: AlertColor;
};

type SnackbarContext = {
  readonly data: SnackbarData | undefined;
  readonly show: (data: SnackbarData) => void;
};

const Ctx = React.createContext<SnackbarContext | undefined>(undefined);

type SnackbarProviderProps = {
  readonly children?: React.ReactNode;
};

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<SnackbarData>();

  const show = React.useCallback((data: SnackbarData) => {
    setData(data);
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => setOpen(false), []);

  const ctx = React.useMemo(() => ({ data, show }), [data, show]);

  return (
    <Ctx.Provider value={ctx}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        message={typeof data?.severity === 'string' ? undefined : data?.message}
        children={
          typeof data?.severity === 'string' ? (
            <Alert variant="filled" severity={data.severity} sx={{ width: '100%' }} onClose={handleClose}>
              {data.message}
            </Alert>
          ) : undefined
        }
      />
    </Ctx.Provider>
  );
}

function useSnackbarContext(): SnackbarContext {
  const ctx = React.useContext(Ctx);

  if (!ctx) {
    throw new Error('useSnackbarContext must be used within a <SnackbarProvider>');
  }

  return ctx;
}

export function useSnackbar() {
  const { show } = useSnackbarContext();

  return { show };
}
