import cn from 'classnames';
import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  readonly label: ReactNode;
  readonly invalid?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(({ id, className, label, invalid, ...props }, ref) => (
  <div className={cn('relative', className)}>
    <input
      {...props}
      ref={ref}
      id={id}
      type="checkbox"
      className={cn(
        'inline-block',
        'w-6 h-6',
        'absolute',
        'top-0 left-0',
        'appearance-none',
        'opacity-0',
        'cursor-pointer',
      )}
    />
    <div
      className={cn(
        'inline-block',
        'absolute',
        'top-0 left-0',
        'w-6 h-6 p-1',
        'border ',
        invalid ? 'border-red-400' : 'border-choosy-gray-lightest sibling-checked:border-choosy-green-dark',
        'rounded-2',
        'bg-white sibling-checked:bg-choosy-green-dark',
        'transition-colors duration-200',
        'cursor-pointer',
        'pointer-events-none',
      )}
    >
      <svg className="fill-current w-full h-full text-white pointer-events-none" version="1.1" viewBox="0 0 17 12">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-9 -11)" fill="currentColor" fillRule="nonzero">
            <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
          </g>
        </g>
      </svg>
    </div>
    <label htmlFor={id} className="block ml-9 cursor-pointer">
      {label}
    </label>
  </div>
));
