import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail,
} from 'firebase/auth';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type FirebaseContext = {
  readonly app: FirebaseApp | undefined;
};

const Ctx = createContext<FirebaseContext | undefined>(undefined);

type Props = {
  readonly children?: ReactNode;
};

export function FirebaseProvider({ children }: Props) {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp>();

  useEffect(() => {
    // initialize firebase
    const app = initializeApp({
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    });
    setFirebaseApp(app);
  }, []);

  const ctx = useMemo(
    () => ({
      app: firebaseApp,
    }),
    [firebaseApp],
  );

  return <Ctx.Provider value={ctx}>{children}</Ctx.Provider>;
}

export function useFirebase(): FirebaseContext {
  const ctx = useContext(Ctx);

  if (!ctx) {
    throw new Error('could not obtain Firebase context, component must be a descendant of <FirebaseProvider>');
  }

  return ctx;
}

type UseFirebaseAuth = {
  readonly auth: Auth | undefined;
  readonly createUserWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  readonly signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  readonly signInWithGoogle: () => Promise<UserCredential>;
  readonly signInWithApple: () => Promise<UserCredential>;
  readonly reauthenticateWithPassword: (password: string) => Promise<UserCredential>;
  readonly reauthenticateWithGoogle: () => Promise<UserCredential>;
  readonly reauthenticateWithApple: () => Promise<UserCredential>;
  readonly signOut: () => Promise<void>;
  readonly sendPasswordResetEmail: (email: string) => Promise<void>;
  readonly updateEmail: (currentEmail: string, newEmail: string, password: string) => Promise<void>;
  readonly deleteAccount: () => Promise<void>;
};

export function useFirebaseAuth(): UseFirebaseAuth {
  const { app } = useFirebase();
  const auth = useMemo(() => (app ? getAuth(app) : undefined), [app]);

  return {
    auth,
    createUserWithEmailAndPassword: useCallback(
      async (email, password) => {
        if (!auth) {
          throw new Error('firebase not yet initialized');
        }
        return await createUserWithEmailAndPassword(auth, email, password);
      },
      [auth],
    ),
    signInWithEmailAndPassword: useCallback(
      async (email, password) => {
        if (!auth) {
          throw new Error('firebase not yet initialized');
        }
        return await signInWithEmailAndPassword(auth, email, password);
      },
      [auth],
    ),
    signInWithGoogle: useCallback(async () => {
      if (!auth) {
        throw new Error('firebase not yet initialized');
      }

      const provider = new GoogleAuthProvider();
      return await signInWithPopup(auth, provider);
    }, [auth]),
    signInWithApple: useCallback(async () => {
      if (!auth) {
        throw new Error('firebase not yet initialized');
      }

      const provider = new OAuthProvider('apple.com');
      return await signInWithPopup(auth, provider);
    }, [auth]),
    reauthenticateWithPassword: useCallback(
      async password => {
        if (!auth) {
          throw new Error('can not reauthenticate with password:firebase not initialized');
        }

        const user = auth.currentUser;

        if (!user) {
          throw new Error('can not reauthenticate with password: not logged in');
        }

        const email = user.email;

        if (!email) {
          throw new Error('can not reauthenticate with password: user has no email');
        }

        const credential = EmailAuthProvider.credential(email, password);

        return await reauthenticateWithCredential(user, credential);
      },
      [auth],
    ),
    reauthenticateWithGoogle: useCallback(async () => {
      if (!auth) {
        throw new Error('can not reauthenticate with Google: firebase not initialized');
      }

      const user = auth.currentUser;

      if (!user) {
        throw new Error('can not reauthenticate with Google: not logged in');
      }

      const provider = new GoogleAuthProvider();
      return await reauthenticateWithPopup(user, provider);
    }, [auth]),
    reauthenticateWithApple: useCallback(async () => {
      if (!auth) {
        throw new Error('can not reauthenticate with Apple: firebase not initialized');
      }

      const user = auth.currentUser;

      if (!user) {
        throw new Error('can not reauthenticate with Apple: not logged in');
      }

      const provider = new OAuthProvider('apple.com');
      return await reauthenticateWithPopup(user, provider);
    }, [auth]),
    signOut: useCallback(async () => {
      if (!auth) {
        throw new Error('firebase not yet initialized');
      }
      return await signOut(auth);
    }, [auth]),
    sendPasswordResetEmail: useCallback(
      async (email: string) => {
        if (!auth) {
          throw new Error('firebase not yet initialized');
        }
        return await sendPasswordResetEmail(auth, email);
      },
      [auth],
    ),
    updateEmail: useCallback(
      async (currentEmail, newEmail, password) => {
        if (!auth) {
          throw new Error('firebase not yet initialized');
        }

        // user needs to have signed in recently for an email update to work
        // see https://firebase.google.com/docs/reference/js/auth#updateemail
        const credentials = await signInWithEmailAndPassword(auth, currentEmail, password);

        if (!credentials) {
          throw new Error('failed to sign in');
        }

        const { user } = credentials;

        if (!user) {
          throw new Error('failed to sign in');
        }

        await updateEmail(user, newEmail);
      },
      [auth],
    ),
    deleteAccount: useCallback(async () => {
      if (!auth) {
        throw new Error('failed to delete account: firebase not initialized');
      }

      const user = auth.currentUser;

      if (!user) {
        throw new Error('failed to delete account: user not authenticated');
      }

      await deleteUser(user);
    }, [auth]),
  };
}

type UseFirebaseAuthState =
  | {
      readonly loading: true;
      readonly loggedIn: false;
      readonly user: undefined;
      readonly error: undefined;
    }
  | {
      readonly loading: false;
      readonly loggedIn: true;
      readonly user: User;
      readonly error: undefined;
    }
  | {
      readonly loading: false;
      readonly loggedIn: false;
      readonly user: undefined;
      readonly error: Error;
    };

export function useFirebaseAuthState(): UseFirebaseAuthState {
  const { auth } = useFirebaseAuth();
  const [user, setUser] = useState<User | undefined>(() => (auth ? auth.currentUser ?? undefined : undefined));
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(!user);

  useEffect(() => {
    if (!auth) {
      return;
    }

    return onAuthStateChanged(
      auth,
      user => {
        setLoading(false);
        if (user) {
          setUser(user);
        } else {
          setUser(undefined);
        }
      },
      err => {
        setLoading(false);
        setUser(undefined);
        setError(err);
      },
    );
  }, [auth]);

  if (loading) {
    return {
      loading,
      loggedIn: false,
      user: undefined,
      error: undefined,
    };
  }

  if (error) {
    return {
      loading: false,
      loggedIn: false,
      user: undefined,
      error,
    };
  }

  if (!user) {
    // invalid state, but we need to handle it
    return {
      loading: false,
      loggedIn: false,
      user: undefined,
      error: new Error('failed to load firebase user'),
    };
  }

  return {
    loading: false,
    loggedIn: true,
    user,
    error: undefined,
  };
}
