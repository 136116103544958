import cn from 'classnames';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { Button } from './Button';
import { TextLink } from './TextLink';
import { Checkbox } from './forms/Checkbox';

const ANALYTICS_ACCEPTED = 'analytics';
const MARKETING_ACCEPTED = 'marketing';

type CookieAcceptance = {
  readonly analytics: boolean;
  readonly marketing: boolean;
};

type CookieConsentContext = {
  readonly consentRequired: boolean;
  readonly essentialCookiesAccepted: boolean;
  readonly analyticsCookiesAccepted: boolean;
  readonly marketingCookiesAccepted: boolean;
  readonly setConsent: (consent: CookieAcceptance) => void;
};

const Ctx = createContext<CookieConsentContext | undefined>(undefined);

type CookieConsentProviderProps = {
  readonly children?: ReactNode;
};

declare global {
  function gtag(...args: any[]): void;
}

export function CookieConsentProvider({ children }: CookieConsentProviderProps) {
  const { cookieExists, essentialAccepted, analyticsAccepted, marketingAccepted, setConsent } = useConsentCookie();
  const gaInitializedRef = useRef(false);

  useEffect(() => {
    if (gaInitializedRef.current !== true) {
      gtag('js', new Date());
      gtag('config', process.env.NEXT_PUBLIC_GA_ID, { anonymize_ip: true });
      gtag('config', process.env.NEXT_PUBLIC_GA_CONVERSION_TAG);
    }

    gaInitializedRef.current = true;

    if (!analyticsAccepted && !marketingAccepted) {
      // by default both are denied, so no need to issue an update
      // see _document.tsx
      return;
    }

    gtag('consent', 'update', {
      analytics_storage: analyticsAccepted ? 'granted' : 'denied',
      ad_storage: marketingAccepted ? 'granted' : 'denied',
    });
  }, [analyticsAccepted, marketingAccepted]);

  const ctx: CookieConsentContext = useMemo(
    () => ({
      consentRequired: !cookieExists,
      essentialCookiesAccepted: essentialAccepted,
      analyticsCookiesAccepted: analyticsAccepted,
      marketingCookiesAccepted: marketingAccepted,
      setConsent,
    }),
    [analyticsAccepted, cookieExists, essentialAccepted, marketingAccepted, setConsent],
  );

  return <Ctx.Provider value={ctx}>{children}</Ctx.Provider>;
}

function useConsentCookie() {
  const [cookies, setCookie] = useCookies(['ChoosyConsent']);
  const rawValue = cookies['ChoosyConsent'];
  const values = typeof rawValue === 'string' ? rawValue.split(',') : [];

  return {
    cookieExists: typeof rawValue === 'string',
    essentialAccepted: true,
    analyticsAccepted: values.indexOf('analytics') >= 0,
    marketingAccepted: values.indexOf('marketing') >= 0,
    setConsent: useCallback(
      (values: CookieAcceptance) => {
        setCookie(
          'ChoosyConsent',
          [...(values.analytics ? [ANALYTICS_ACCEPTED] : []), ...(values.marketing ? [MARKETING_ACCEPTED] : [])].join(
            ',',
          ),
          {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
          },
        );
      },
      [setCookie],
    ),
  };
}

export function useCookieConsent(): CookieConsentContext {
  const ctx = useContext(Ctx);

  if (!ctx) {
    throw new Error('useCookieConsent must be used from within a <CookieConsentProvider>');
  }

  return ctx;
}

export default function CookieConsentBanner() {
  const { consentRequired, setConsent } = useCookieConsent();
  const [analyticsChecked, setAnalyticsChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);

  if (!consentRequired) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="fixed top-0 bottom-0 left-0 right-0">
      <div className="w-full h-full bg-black opacity-33" />
      <div
        className={cn(
          'absolute top-0 bottom-0 left-0 right-0',
          'flex flex-col items-center justify-center',
          'p-4 sm:p-8 md:p-12 lg:p-16',
        )}
      >
        <div
          className={cn(
            'w-auto md:w-152.5',
            'p-4 sm:p-6 lg:p-8 xl:p-10',
            'flex flex-col',
            'bg-white',
            'border border-gray-100',
            'rounded-2xl',
            'shadow-lg',
            'overflow-y-auto',
          )}
        >
          <div className="text-16px sm:text-18px lg:text-18px xl:text-20px min-h-0 overflow-y-auto">
            Wir nutzen auf dieser Website Cookies. Einige von ihnen sind essentiell, während andere uns helfen, diese
            Website und dadurch deine Nutzererfahrung zu verbessern. Weitere Informationen findest du in der{' '}
            <TextLink href="/privacy-policy" target="_blank">
              Datenschutzerklärung
            </TextLink>
            .
          </div>
          <div className="mt-6 flex flex-col">
            <Checkbox
              id="essential"
              className=""
              disabled={true}
              checked={true}
              label={<span className="text-16px sm:text-18px lg:text-18px xl:text-20px">Technisch notwendig</span>}
            />
            <Checkbox
              id="analytics"
              className="mt-3"
              label={<span className="text-16px sm:text-18px lg:text-18px xl:text-20px">Analyse</span>}
              checked={analyticsChecked}
              onChange={e => setAnalyticsChecked(e.target.checked)}
            />
            <Checkbox
              id="marketing"
              className="mt-3"
              label={<span className="text-16px sm:text-18px lg:text-18px xl:text-20px">Marketing</span>}
              checked={marketingChecked}
              onChange={e => setMarketingChecked(e.target.checked)}
            />
          </div>
          <div className="mt-6 w-full md:flex md:flex-row flex-wrap justify-center">
            <Button
              className="w-full md:w-auto"
              size="md"
              theme="secondary-light"
              onClick={() => setConsent({ analytics: analyticsChecked, marketing: marketingChecked })}
            >
              Nur notwendige speichern
            </Button>
            <Button
              className="mt-4 md:mt-0 md:ml-6 w-full md:w-auto"
              size="md"
              onClick={() => setConsent({ analytics: true, marketing: true })}
            >
              Alle speichern
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
}
