import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { FirebaseProvider } from '../components/FirebaseProvider';
import '../index.css';
import { CookieConsentProvider } from '../components/cookie-consent';
import { CookiesProvider } from 'react-cookie';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { IKContext } from 'imagekitio-react';
import { SnackbarProvider } from '../components/SnackbarProvider';

const CookieConsentBanner = dynamic(() => import('../components/cookie-consent'), { ssr: false });

export default function _App({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
          },
        },
      }),
  );
  const router = useRouter();

  return (
    <SnackbarProvider>
      <CookiesProvider>
        <CookieConsentProvider>
          <FirebaseProvider>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <IKContext urlEndpoint={process.env.NEXT_PUBLIC_IMAGEKIT_URL_ENDPOINT}>
                  <Head>
                    <title>Choosy - Essensplanung so schnell und einfach wie nie</title>
                    <meta
                      name="description"
                      content="Choosy findet, was dir schmeckt. Lecker und ausgewogen durch die Woche. Individuell. Gesund. Abwechslungsreich."
                    />
                    <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=0" />
                    <meta name="application-name" content="Choosy" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-title" content="Choosy" />
                    <meta name="msapplication-TileColor" content="#3AC8B2" />
                    <meta name="theme-color" content="#3AC8B2" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="#3AC8B2" />
                    <meta name="msapplication-TileImage" content="/favicons/mstile-150x150.png?v=202111182104" />
                    <meta name="msapplication-square70x70logo" content="/favicons/mstile-70x70.png?v=202111182104" />
                    <meta
                      name="msapplication-square150x150logo"
                      content="/favicons/mstile-150x150.png?v=202111182104"
                    />
                    <meta name="msapplication-wide310x150logo" content="/favicons/mstile-310x150.png?v=202111182104" />
                    <meta
                      name="msapplication-square310x310logo"
                      content="/favicons/mstile-310x310.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="57x57"
                      href="/favicons/apple-touch-icon-57x57.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="60x60"
                      href="/favicons/apple-touch-icon-60x60.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="72x72"
                      href="/favicons/apple-touch-icon-72x72.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="76x76"
                      href="/favicons/apple-touch-icon-76x76.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="114x114"
                      href="/favicons/apple-touch-icon-114x114.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="120x120"
                      href="/favicons/apple-touch-icon-120x120.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="144x144"
                      href="/favicons/apple-touch-icon-144x144.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="152x152"
                      href="/favicons/apple-touch-icon-152x152.png?v=202111182104"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="180x180"
                      href="/favicons/apple-touch-icon-180x180.png?v=202111182104"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="36x36"
                      href="/favicons/android-chrome-36x36.png?v=202111182104"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="48x48"
                      href="/favicons/android-chrome-48x48.png?v=202111182104"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="72x72"
                      href="/favicons/android-chrome-72x72.png?v=202111182104"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="96x96"
                      href="/favicons/android-chrome-96x96.png?v=202111182104"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="144x144"
                      href="/favicons/android-chrome-144x144.png?v=202111182104"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="192x192"
                      href="/favicons/android-chrome-192x192.png?v=202111182104"
                    />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png?v=202111182104" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png?v=202111182104" />
                    <link rel="icon" type="image/png" sizes="96x96" href="/favicons/favicon-96x96.png?v=202111182104" />
                    <link rel="shortcut icon" type="image/x-icon" href="favicons/favicon.ico?v=202111182104" />
                    <link
                      href="/favicons/apple-touch-startup-image-320x460.png?v=202111182104"
                      media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-640x920.png?v=202111182104"
                      media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-640x1096.png?v=202111182104"
                      media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-748x1024.png?v=202111182104"
                      media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 1) and (orientation: landscape)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-750x1024.png?v=202111182104"
                      media=""
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-750x1294.png?v=202111182104"
                      media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-768x1004.png?v=202111182104"
                      media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 1) and (orientation: portrait)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-1182x2208.png?v=202111182104"
                      media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-1242x2148.png?v=202111182104"
                      media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-1496x2048.png?v=202111182104"
                      media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                      rel="apple-touch-startup-image"
                    />
                    <link
                      href="/favicons/apple-touch-startup-image-1536x2008.png?v=202111182104"
                      media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                      rel="apple-touch-startup-image"
                    />
                  </Head>
                  <Component {...pageProps} />
                  {router.asPath !== '/privacy-policy' ? <CookieConsentBanner /> : null}
                </IKContext>
              </Hydrate>
            </QueryClientProvider>
          </FirebaseProvider>
        </CookieConsentProvider>
      </CookiesProvider>
    </SnackbarProvider>
  );
}
