import cn from 'classnames';

export type IconId =
  | 'arrow-left'
  | 'camera'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'edit'
  | 'external-link'
  | 'instagram'
  | 'link'
  | 'plus'
  | 'refresh-ccw'
  | 'refresh-cw'
  | 'send'
  | 'trash'
  | 'trash-2'
  | 'x';

type Props = {
  readonly id: IconId;
  readonly className?: string;
};

export function Icon({ id, className }: Props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn('inline', className)}
      style={{ width: '1em', height: '1em' }}
    >
      <use href={`/images/feather-sprite.svg#${id}`} />
    </svg>
  );
}
