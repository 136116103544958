import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from 'react';
import cn from 'classnames';
import { Icon, IconId } from './Icon';

type Size = 'md' | 'lg';

const DEFAULT_SIZE: Size = 'lg';

const SIZE_CLASS_NAMES: { [S in Size]: string } = {
  md: cn('px-6 py-1.5', 'text-14px sm:text-16px lg:text-18px xl:text-20px'),
  lg: cn('px-10 py-2', 'text-16px sm:text-18px lg:text-20px xl:text-24px'),
};

type Theme = 'primary' | 'secondary' | 'secondary-light' | 'danger' | 'google' | 'apple';

const DEFAULT_THEME: Theme = 'primary';

const THEME_CLASS_NAMES: { [T in Theme]: string } = {
  'primary': cn('bg-choosy-green-dark hover:bg-choosy-green-lighter', 'text-white', 'font-extrabold'),
  'secondary': cn(
    'border border-gray-100 hover:border-choosy-green-dark',
    'bg-white hover:bg-choosy-green-dark',
    'text-choosy-gray hover:text-white',
    'font-extrabold',
  ),
  'secondary-light': cn(
    'border border-gray-100 hover:border-choosy-green-dark',
    'bg-white hover:bg-choosy-green-dark',
    'text-choosy-gray-light hover:text-white',
    'font-semibold',
  ),
  'danger': cn('bg-red-600 hover:bg-red-500', 'text-white', 'font-extrabold'),
  'google': cn('border border-gray-100', 'bg-white hover:bg-gray-100', 'text-choosy-darkgray', 'font-extrabold'),
  'apple': cn('border border-black', 'bg-black hover:bg-gray-800', 'text-white', 'font-extrabold'),
};

function commonClassNames({}: { disabled?: boolean }) {
  return cn(
    'inline-flex items-center justify-center',
    'rounded-full',
    'shadow-md',
    'transition-colors duration-150',
    'cursor-pointer',
    'disabled:opacity-50',
  );
}

type LayoutProps = {
  readonly size?: Size;
  readonly theme?: Theme;
  readonly icon?: IconId;
};

export const Button = forwardRef<HTMLButtonElement, LayoutProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ size = DEFAULT_SIZE, theme = DEFAULT_THEME, icon, disabled, className, children, ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      disabled={disabled}
      className={cn(commonClassNames({ disabled }), SIZE_CLASS_NAMES[size], THEME_CLASS_NAMES[theme], className)}
    >
      {icon ? <Icon id={icon} className="mr-1" /> : null}
      {children}
    </button>
  ),
);

export const ButtonLink = forwardRef<HTMLAnchorElement, LayoutProps & AnchorHTMLAttributes<HTMLAnchorElement>>(
  ({ size = DEFAULT_SIZE, theme = DEFAULT_THEME, icon, className, children, ...props }, ref) => (
    <a
      {...props}
      ref={ref}
      className={cn(commonClassNames({ disabled: false }), SIZE_CLASS_NAMES[size], THEME_CLASS_NAMES[theme], className)}
    >
      {icon ? <Icon id={icon} className="mr-1" /> : null}
      {children}
    </a>
  ),
);

export const ButtonLabel = forwardRef<HTMLLabelElement, LayoutProps & LabelHTMLAttributes<HTMLLabelElement>>(
  ({ size = DEFAULT_SIZE, theme = DEFAULT_THEME, icon, className, children, ...props }, ref) => (
    <label
      {...props}
      ref={ref}
      className={cn(commonClassNames({ disabled: false }), SIZE_CLASS_NAMES[size], THEME_CLASS_NAMES[theme], className)}
    >
      {icon ? <Icon id={icon} className="mr-1" /> : null}
      {children}
    </label>
  ),
);

export const ButtonFileInput = forwardRef<
  HTMLInputElement,
  LayoutProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'>
>(({ size = DEFAULT_SIZE, theme = DEFAULT_THEME, icon, disabled, className, children, ...props }, ref) => (
  <label
    className={cn(
      'focus-within:shadow-blue-700',
      commonClassNames({ disabled }),
      SIZE_CLASS_NAMES[size],
      THEME_CLASS_NAMES[theme],
      className,
    )}
  >
    <input {...props} type="file" className="visually-hidden" ref={ref} disabled={disabled} />
    {icon ? <Icon id={icon} className="mr-1" /> : null}
    {children}
  </label>
));
